<template>
	<div class="main">
		<div class="code">
			<span class="title">现金封包录入系统</span>
		</div>
		<div class="mbody">
			<div class="c1">
				<div>门店编号：<span class="text_color_blue">{{info.shopsNo}}</span></div>
				<div>门店名称：<span class="text_color_blue">{{info.shopsName}}</span></div>
				<div>用户信息：<span class="text_color_blue">{{info.name + '（' + info.userid +'）' }}</span></div>
				<div>账户名称：<span class="text_color_blue">{{info.accountName}}</span></div>
				<div>开户行：<span class="text_color_blue">{{info.accountKhh}}</span></div>
				<div>存款账号：<span class="text_color_blue">{{info.accountNo}}</span></div>
				<div>安保负责人：<span class="text_color_blue">{{info.lxrName + '（' + info.lxrPhone +'）' }}</span></div>
			</div>
			<div class="c2">
				<div class="title">
					营业款-新增
				</div>
				<div class="content">
					<van-form>
						<van-field readonly v-model="item.bagDate" label="营业款日期:" placeholder="请输入:营业款日期" @click="showDate()"/>
						<van-field v-model="item.bagFee" center clearable  label="营业款金额:" placeholder="请输入:营业款金额" use-button-slot>
							<van-button slot="button" size="mini" type="primary" @click="submit">添加</van-button>
						</van-field>
					</van-form>
				</div>
			</div>

			<div class="c2 bagList">
				<div class="title">
					营业款-历史
				</div>
				<div class="btns">
					<div>封包总数：<font class="text_color_red">{{totalBagSize}}</font>
					</div>
					<div>总金额：<font class="text_color_red">￥{{totalBagFee}}</font>
					</div>
				</div>
				<div v-for="val , key in list" :key="key" class="content">
					<van-form>
						<van-icon name="coupon-o" :badge="key+1" v-if="val.status==0"/>
						<van-field center clearable readonly :value="val.bagDate" label="营业款日期:" input-class="text_color_blue" use-button-slot>
							<van-button v-if="val.status==0" slot="button" data-id="val.id" size="mini" type="danger" @click="remove(val)">删除</van-button>
							<van-button v-if="val.status==1" slot="button" data-id="val.id" size="mini" type="primary">已交接</van-button>
						</van-field>
						<van-field readonly :value="val.bagFee" type="text" label="营业款金额:" />
						<van-field readonly :value="val.createTime" label="录入时间:" />
						<van-field readonly :value="val.userName" label="录入姓名:" />
						<van-field readonly :value="val.userWorkNo" label="录入工号:" />
						<van-field readonly :value="val.pickTime" label="交接时间:" v-if="val.status==1" />
					</van-form>
				</div>
			</div>
		</div>

		<van-popup v-model="date_show" position="bottom">
			<van-datetime-picker @cancel="date_show = false" v-model="item.bagDateStr" @confirm="dateConfirm"
				type="date" title="封包日期" :min-date="min_date" :max-date="max_date" />
		</van-popup>

		<Loading :isLoad="isLoad"></Loading>
	</div>
</template>
<script>
export default {
	data() {
		return {
			item: {
				bagDate: '',
				bagFee: '',
				bagDateStr: ''
			},
			isLoad:false,
			date_show:false,
			min_date: new Date(new Date().getFullYear(), new Date().getMonth()-1, 1),
			max_date: new Date(),
			info: {
				name: '',
				userid: '',
				shopsNo: '',
				shopsId: '',
				accountName: '',
				accountNo: '',
				accountKhh: '',
				lxrName: '',
				lxrPhone: ''
			},
			totalBagSize: 0,
			totalBagFee: 0,
			list:[]
		}
	},
	created() {
		this.getInfo();
	},
	methods: {
		async loadData(){
			if(this.isLoad)
				return
			this.loadList()
		},
		loadList(){
			if(this.isLoad)
				return
			this.isLoad = !this.isLoad
			this.axios.post('/api/cash/list').then(res => {
				this.isLoad = !this.isLoad
				if(res.code == 0){
					this.list = res.data.list
					this.totalBagSize = res.data.totalBagSize
					this.totalBagFee = res.data.totalBagFee
				}
			}).catch(() => {
				this.isLoad = !this.isLoad
			})
		},
		async getInfo(){
			if(this.isLoad)
				return
			this.isLoad = !this.isLoad
			this.axios.post('/api/cash/getInfo').then(res => {
				this.isLoad = !this.isLoad		
				let needLogin = true;
				if(res.code == 0 && res.data.shopsNo && res.data.shopsNo!=""){
					needLogin = false;
					this.info = res.data
					// if(this.info.userid == '17759216515'){
					// 	// 我的测试账号
					// 	this.info.userid = '18812345678';
					// 	this.info.name = '张三';
					// 	this.info.lxrName = '李四';
					// 	this.info.lxrPhone = '17712345678';
					// }
					// 加载列表数据
					this.loadData();
				}
				if(needLogin === true){
					// 票据失效
					localStorage.removeItem('token');
				}
			}).catch(() => {
				this.isLoad = !this.isLoad
			})
		},
		showDate(){
			if(typeof this.item.bagDateStr == 'undefined' || this.item.bagDateStr == ''){
				this.item.bagDateStr = new Date();				
			}
			this.date_show = true
		},
		dateConfirm(e) {
			let year = e.getFullYear();
			let month = e.getMonth() + 1;
			let day = e.getDate();
			if (month < 10)
				month = "0" + month
			if (day < 10)
				day = "0" + day
			
			this.item.bagDate = year + '-' + month + '-' + day
			this.date_show = false
		},
		remove(e){
			this.$dialog.confirm({
				message: '确认要删除封包？',
			}).then(()=>{
				if(this.isLoad)
					return
				this.isLoad = !this.isLoad
				let that = this;
				this.axios.post('/api/cash/remove',{
					id:e.id
				}).then(res => {
					this.isLoad = !this.isLoad
					if (res.code === 0) {
						this.$notify({
							type:'success',
							message: "删除成功！"
						})
						that.loadList();
					}else {
						this.$notify({
							type:'warning',
							message: res.msg
						})
					}
				}).catch(() => {
					this.isLoad = !this.isLoad
				})				
			}).catch(() => {
			});
		},
		submit() {
			if(!this.item.bagDate){
				this.$notify({
					type:'warning',
					message: "请选择：营业款日期"
				})
				return
			}
			this.item.bagFee = this.item.bagFee.trim();
			if(!this.item.bagFee){
				this.$notify({
					type:'warning',
					message: "请输入：营业款金额"
				})
				return
			}
			if(this.isLoad)
				return
			this.isLoad = !this.isLoad
			let that = this;
			this.axios.post('/api/cash/save',{
				bagDate:this.item.bagDate,
				bagFee: this.item.bagFee,
				shopsId: this.info.shopsId
			}).then(res => {
				this.isLoad = !this.isLoad
				if (res.code === 0) {
					this.$notify({
						type:'success',
						message: "保存成功"
					})
					that.item = {
						bagDate: '',
						bagFee: '',
						bagDateStr: ''
					};
					that.loadList();
				}else {
					this.$notify({
						type:'warning',
						message: res.msg
					})
				}
			}).catch(() => {
				this.isLoad = !this.isLoad
			})
		}
	},
	watch:{
	}
}
</script>

<style>
.van-field__control {
	color: blue !important;
}
.bagList .van-info{
	background-color: #07c160 !important;
}
</style>

<style scoped>
.text_color_blue{
	color: blue;
}
.text_color_red{
	color: red;
}
.btns{
	display: flex;
	justify-content: space-between;
	margin-top: .5rem;
	padding: 0 .5rem;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;
}
.btns div {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5rem;
}
.btns button{
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: .15rem;
	height: 1.5rem;
	margin: 0 auto;
	width: 1rem;
	flex-grow: 1;
	background-image: linear-gradient(to right, #8551d9,  #7765eb);
	margin-left: .5rem;
}
.btns button:nth-child(1){
	margin-left: 0;
}
.content-btns{
	display: flex;
	justify-content: space-between;
	margin-top: .5rem;
}
.content-btns button{
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: .15rem;
	height: 1.5rem;
	margin: 0 auto;
	width: 1rem;
	flex-grow: 1;
	background-image: linear-gradient(to right, #8551d9,  #7765eb);
	margin-left: .5rem;
}
.content-btns button:nth-child(1){
	margin-left: 0;
}
.content-btns button:nth-child(2){
	background: #16d46b;
}
.main {
    display: block;
    width: 100%;
    margin: 0 auto;
    color: #666666;
}

.mbody {
    display: block;
    width: 90%;
    margin: 0 auto;
}

.main img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
}

.c1 {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
}

.c1 div {
    display: flex;
    justify-content: left;
    align-items: center;
    line-height: 1.5rem;
    border-bottom: 1px solid #cccccc;
}
.c1 span{
	display: inline-block;
	white-space: nowrap;
	width: 60%;
}

.heart {
    display: flex;
    align-items: center;
}

.c2 {
    display: flex;
    flex-direction: column;
}

.c2 .content {
    border: 1px solid #cccccc;
    padding: 0.5rem;
    border-radius: 0.25rem;
    margin: 0.5rem 0;
}

.c2 .content div:last-child {
    border-bottom: none;
}

.c2 .content input {
    border: none;
    color: #666666;
    text-align: right;
    padding: 0;
}

.c2 div select {
    color: #666666;
}

.c2 .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.c2 .title span {
    background: #16d46b;
    border: 1px solid #16d46b;
    border-radius: 0.2rem;
    padding: 0.3rem;
    color: #ffffff;
}

.c2 .menu {
    display: flex;
    justify-content: space-between;
}

.c2 .menu span {
    background: #EFEFEF;
    border: 1px solid #cccccc;
    border-radius: 0.2rem;
    padding: 0.3rem;
}

.c2 .menu span:last-child {
    border: 1px solid #16d46b;
    margin-right: 0px;
    background: #16d46b;
    color: #ffffff;
}

.c2 .menu .title {
    display: flex;
    border: none;
    background: none;
    flex-grow: 1;
    justify-content: flex-start;
}

.mbody .c2:last-child {
    margin-bottom: 5rem;
}

.box-span {
    display: flex;
	align-items: center;
    margin-top: 0.2rem;
    margin-left: 0.6rem;
}

.box-span van-button {
    margin-left: 1rem;
}

.box-butt {
    margin-left: 4.7rem;
}

.an{
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: .15rem;
	height: 2rem;
	width: 15rem;
	margin: 0 auto;
	margin-top: 1rem;
	background-image: linear-gradient(to right, #8551d9,  #7765eb);
}
.auth-bg{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.auth-bg .auth{
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: .15rem;
	height: 1.5rem;
	width: 4rem;
	background-image: linear-gradient(to right, #8551d9,  #7765eb);
}
.white-bg{
	width: 17.75rem;
	border-radius: .15rem;
}
.white-bg .an{
	width: 100%;
	border-radius: 0;
}
.white-bg .white-title{
	font-size: .8rem;
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	margin-bottom: .5rem;
}
.ans{
	display: flex;
	justify-content: space-between;
}
.ans button{
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: .15rem;
	height: 2rem;
	margin: 0 auto;
	width: 1rem;
	flex-grow: 1;
	background-image: linear-gradient(to right, #8551d9,  #7765eb);
	margin-left: .5rem;
}
.ans button:nth-child(1){
	margin-left: 0;
}
.ans button:nth-child(2){
	background: #16d46b;
}

.code {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: .15rem;
	height: 2rem;
	margin: 0 auto;
	width: 100%;
	flex-grow: 1;
	background-image: linear-gradient(to right, #8551d9,  #7765eb);
}
</style>